import React, { useState, useEffect } from "react";

const VWTab = () => {
 
  useEffect(() => {
console.log('Vic Waterfalls')
  }, []);

  return (
    <div className="container">
      <h1>Vic Waterfalls</h1>
    </div>
  );
};

export default VWTab;
