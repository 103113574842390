//// EXTERNAL
import React, { Fragment } from "react";
// import ReactDOM from "react-dom";
import {
  Switch,
  Route,
  Link,
  BrowserRouter,
  // Redirect
} from "react-router-dom";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import ContainerMainPage from "./Components/ContainerMainPage";
import CardMainPage from "./Components/CardMainPage";
//// CSS
import "./App.css";

function App(props) {
  // console.log(props);
  return (
    <BrowserRouter>
      <div className="App">
        <Route
          path="/"
          render={({ location }) => (
            <Fragment>
              {/* <AppBar position="static">
                <Tabs value={location.pathname}>
                  {props.tabs.map ((x, i) => <Tab key={i} label={x.label} value={x.link} component={Link} to={x.link} />)}
                </Tabs>
              </AppBar> */}
              <ContainerMainPage>
                {props.links.map((x, i) => (
                  <CardMainPage
                    key={i}
                    description={x.description}
                    imglink={x.imglink}
                    label={x.label}
                    link={x.link}
                    externallink={x.externallink}
                    externallinktitle={x.externallinktitle}
                  />
                ))}
                {props.tabs.map((x, i) => (
                  <CardMainPage
                    key={i}
                    description={x.description}
                    imglink={x.imglink}
                    label={x.label}
                    link={x.link}
                    externallink={x.externallink}
                    externallinktitle={x.externallinktitle}
                  />
                ))}
              </ContainerMainPage>

              <Switch>
                {props.tabs.map((x, i) => (
                  <Route key={i} exact path={x.link} component={x.component} />
                ))}
              </Switch>
            </Fragment>
          )}
        />
      </div>
    </BrowserRouter>
  );
}
export default App;
