import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import COVIDTab from "./Tabs/COVIDTab";
import CanWeCatchUpTab from "./Tabs/CanWeCatchUpTab";
import VDFTab from "./Tabs/VDFTab";
import VWTab from "./Tabs/VWTab";

import reportWebVitals from "./reportWebVitals";

const allTabs = {
  links: [
    // {
    //   link: "https://melbournedroneflyers.org/",
    //   label: "Vic Drone Flyers",
    //   description: "Map for Victorian Drone Flyers FB group. Finds locations where Drones can legally be launched based on National Parks and Council Local Laws. Doesn't replace, but compliements CASA map",
    //   externallink: "https://melbournedroneflyers.org/",
    //   externallinktitle: "Vic Drone Flyers",
    //   imglink: "./img/mdf-card.png",
    //   component: VDFTab,
    // },
    {
      link: "https://www.canwecatchup.com.au/",
      label: "CanWeCatchUp",
      description:
        "Map to find a location for catching up during COVID-19 restrictions. Finds areas within set limit for multiple participants.",
        externallink: "https://www.canwecatchup.com.au/",
        externallinktitle: "CanWeCatchUp",
      imglink: "./img/canwe-card.jpg",
      component: CanWeCatchUpTab,
    },
  ],
  tabs: [
    {
      link: "https://vicwaterfalls.midkhat.com.au",
      label: "Vic Waterfalls",
      description:
        "Map for Victorian Waterfalls FB group. Locates Waterfalls in Victoria, has an option of searching waterfalls within set radius and adding new waterfalls with FB credentials.",
      imglink: "./img/vic_waterfalls-card.jpg",
      component: VWTab,
    },
    {
      link: "https://nemtcov.midkhat.com.au",
      label: "Assassination of Boris Nemtcov",
      description:
        "The map of phone calls of the suspects in the case of Boris Nemtcov assassination in 2015. Based on times and locations possible meetings of the suspects were identified. The map was used in journalist investigation (published in Russian):",
      externallink: "https://zona.media/article/2020/11/02/nemtsov",
      externallinktitle: "Zona.media",
      imglink: "./img/nemtcov-card.jpg",
      component: CanWeCatchUpTab,
    },
    {
      link: "https://vicrailwaydams.midkhat.com.au",
      label: "Victorian Railways and Dams",
      description:
        "The map of the history of Victorian railway construction and Water Reservoirs.",
      imglink: "./img/vic-rail-dambs-card.jpg",
      component: CanWeCatchUpTab,
    },

    // {
    //   link: "/covid-19",
    //   label: "COVID 19",
    //   description: "COVID 19 Dashboard",
    //   imglink: "./img/covid-card.png",
    //   component: COVIDTab,
    // },
  ],
};

ReactDOM.render(
  <App tabs={allTabs.tabs} links={allTabs.links} />,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
