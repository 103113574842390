import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import "./Components.css"
const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    borderRadius: 5,
    border: "1px red solid",
    margin: 10,
    padding: 5,
  },
});

const ContainerMainPage = (props) => {
//   const classes = useStyles();

  return <div className="contaioner_main_page">{props.children}</div>;
};

export default ContainerMainPage;
