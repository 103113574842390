import React, { useState, useEffect } from "react";

const CanWeCatchUpTab = () => {
 
  useEffect(() => {
console.log('Can we catch up')
  }, []);

  return (
    <div className="container">
      <h1>Can we catch up</h1>
    </div>
  );
};

export default CanWeCatchUpTab;
