import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "@material-ui/core";
// const useStyles = makeStyles({
//   root: {
//     maxWidth: 345,
//     borderRadius: 5,
//     border: "1px red solid",
//     margin: 10,
//     padding: 5
//   },
// });

const CardMainPage = (props) => {
  //   const classes = useStyles();
  // console.log(props)
  console.log(props.externallink);
  return (
    // <Card className={classes.root}>
    <Card className="card_main_page">
      <CardActionArea component="div">
        <Link href={props.link}>
          <CardMedia
            component="img"
            alt={props.label}
            height="140"
            image={props.imglink}
            title={props.description}
          />

          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {props.label}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {props.description}
            </Typography>
          </CardContent>
        </Link>
      </CardActionArea>
      <CardActions>
        {
          <Button size="small" color="primary" href={props.externallink}>
            {props.externallinktitle}
          </Button>
        }
        {/* {<Button size="small" color="primary">
          Learn More
        </Button>} */}
      </CardActions>
    </Card>
  );
};

export default CardMainPage;
